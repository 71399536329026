.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  border-radius: 20px;
  background-color: #f2f5f9;
}
.link {
  margin-left: 10px;
  font-weight: bold;
  color: rgb(0, 102, 255);

  &:hover {
    color: rgba(0, 102, 255, 0.7);
  }
}
.policy {
  display: inline-block;
  font-weight: normal;
  color: rgba(0, 102, 255, 0.6);

  &:hover {
    color: rgba(0, 102, 255, 1);
  }
}
.minilink {
  font-weight: normal;
  color: rgba(0, 102, 255, 0.6);

  &:hover {
    color: rgba(0, 102, 255, 1);
  }
}
